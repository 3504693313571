<template>
  <VueFinalModal
    class="flex flex-col items-center justify-center h-full !z-[99999999] modal_wrapper"
    click-to-close
    content-class="absolute inset-0"
    display-directive="if"
    @update:model-value="(val) => emit('update:modelValue', false)"
  >
    <div
      class="absolute inset-0 h-full overflow-auto"
      @click.self="() => emit('update:modelValue', false)"
    >
      <div class="flex flex-col max-w-xl mx-auto my-12 bg-white rounded">
        <div v-if="title" class="w-full px-4 py-6 text-left border-b">
          <h4
            class="text-xl font-semibold text-gray-600 uppercase"
            v-text="title"
          />
        </div>
        <div
          :class="modalBodyClass"
          class="w-full text-base rounded-lg shadow-lg"
        >
          <slot />
        </div>
        <div
          v-if="hasSlot('footer')"
          class="w-full p-4 border-t sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
        >
          <slot name="footer" />
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { VueFinalModal } from 'vue-final-modal'

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  modalBodyClass: {
    type: [Array, String],
    default: 'px-4 py-2 max-w-xl',
  },
})
const emit = defineEmits(['update:modelValue'])

const slots = useSlots()

function hasSlot(name = 'default') {
  return !!slots[name] || !!slots.default[name]
}
</script>

<style lang="postcss">
.modal_wrapper .vfm__overlay {
  @apply bg-gray-500 bg-opacity-60;
}

.modal_wrapper .vfm__content {
  @apply items-center justify-center flex;
}
</style>
